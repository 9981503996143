<template>
  <div class="goshinboku" id="goshinboku">
    <Nav/>
      <main id="main" role="main">
        <section id="lead">
            <h2><img src="@/assets/img/head_goshinboku.svg" alt="しなの木"></h2>
            <TxtAnime>樹齢1000年以上のご神木です。このご神木が信濃（現在の長野県）の国名の語源となったと云われています。（諸説あり）「結ぶ・くくる・しばる」という意味で、古来より開運・縁結びのご神木として信仰されてきました。今も軽井沢のパワースポットのひとつとして参拝者の信仰を集めています。しなの木の葉はハートの形をしており、7月頃に白く小さい花をたくさん咲かせます。</TxtAnime>
            <div class="box">
              <slick ref="slick" :options="slickOptions">
                <a href=""><img src="@/assets/img/shinanoki01.jpg" alt="御神木のしなの木"></a>
                <a href=""><img src="@/assets/img/shinanoki02.jpg" alt="御神木のしなの木"></a>
              </slick>
            </div>
          </section>
      </main>
    <Foot/>
  </div>
</template>

<script>
import Foot from '@/components/Foot.vue'
import Nav from '@/components/Nav.vue'
import TxtAnime from '@/components/TxtAnime.vue'

import Slick from 'vue-slick'
import '../../node_modules/slick-carousel/slick/slick.css'

export default {
  name: 'Goshinboku',
  components: {
    Foot,
    Nav,
    Slick,
    TxtAnime
  },

  data () {
    return {
      slickOptions: {
        dots: true,
        arrows: false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  p {
      font-family: 'NuKinakoMochi-BarVtE-1'
  }
</style>
